import React from "react";
import NavbarComponent from "../Navbar/NavbarComponent";
import NavHead from "../NavHead/NavHead";

const Header = () => {
  return (
    <>
      <NavbarComponent />
    </>
  );
};

export default Header;

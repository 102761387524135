import "./App.css";
import Routing from "./components/Routes/Routing";

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
